// Animated accordion
// populate images in the accordion-items rather than constantly replacing the img src props
const setAnimatedAccordionImages = () => {
  const animatedAccordions = document.querySelectorAll('.bam-animated-accordion');
  animatedAccordions.forEach(animatedAccordion => {
    // check if accordion is single or multi
    const mediaColumn = animatedAccordion.querySelector('.bam-animated-accordion__media');
    const mediaImageContainer = mediaColumn.querySelector(
      '.bam-animated-accordion__image .caption-image-wrapper div'
    );
    const mediaMobileImageContainer = mediaColumn.querySelector(
      '.bam-animated-accordion__image .caption-image-wrapper div.d-block.d-sm-none.text-center'
    );
    const mediaImage = mediaImageContainer.querySelector('img');
    const hasMediaColumnImage = mediaImage.getAttribute('src');
    const accordionColumn = animatedAccordion.querySelector('.bam-accordion-section__container');
    const hasMobileImage = animatedAccordion.classList.contains(
      'bam-animated-accordion__mobile-image--show'
    );

    if (hasMediaColumnImage && !hasMobileImage) {
      // removes mobile image if hidden
      mediaMobileImageContainer.remove();
    }
    if (!hasMediaColumnImage) {
      // removes mobile image from media column for multi image
      mediaMobileImageContainer.remove();
      mediaImage.remove();

      // displays multi images on mobile view
      if (hasMobileImage) {
        mediaImageContainer.classList.remove('d-none', 'd-sm-block');
        mediaImageContainer.classList.add('d-block');
      }

      // set multi image
      const accordionImages = accordionColumn.querySelectorAll('img');
      accordionImages.forEach((image, index) => {
        const newImage = document.createElement('img');
        // set newImage attributes
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < image.attributes.length; i++) {
          newImage.setAttribute(image.attributes[i].name, image.attributes[i].value);
        }
        newImage.classList.add(
          'animation',
          index === 0 ? 'fade-in' : 'hide',
          index !== 0 ? 'absolute' : 'relative',
          index === 0 ? 'animated' : 'hide'
        );
        mediaImageContainer.appendChild(newImage);
      });
    }
  });
};

const setAnimatedAccordionImage = (accordionImages, targetIndex) => {
  let activeImageIndex = 0;
  accordionImages.forEach((image, index) => {
    if (image.classList.contains('fade-in')) {
      activeImageIndex = index;
    }
  });

  if (targetIndex === activeImageIndex) {
    return;
  }

  if (accordionImages[targetIndex].classList.contains('hide')) {
    accordionImages[targetIndex].classList.remove('hide');
    accordionImages[targetIndex].classList.add('fade-in', 'animated');
  } else {
    accordionImages[targetIndex].classList.remove('fade-out');
    accordionImages[targetIndex].classList.add('fade-in', 'animated');
  }
  accordionImages[activeImageIndex].classList.remove('fade-in', 'animated');

  accordionImages[activeImageIndex].classList.add('fade-out');
};

const initAnimatedAccordion = element => {
  const mediaColumn = element.querySelector('.bam-animated-accordion__media');
  const mediaColumnImages = mediaColumn.querySelectorAll(
    '.bam-animated-accordion__image .d-block img'
  );
  const accordionSection = element.querySelector('.bam-accordion-section__container');
  const accordionItems = accordionSection.querySelectorAll('.wp-block-cgb-bam-accordion-item');
  const progressBar = accordionSection.querySelectorAll('.bam-accordion-item__progress-bar-timer');
  const maxDuration = 8000; // runs for 8 seconds

  const io = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // set first progressBar style
          progressBar[0].style = `width: 100%; transition: width ${maxDuration}ms linear;`;

          let count = 1;
          accordionItems[0].querySelector('input[type="checkbox"]').checked = true;
          const loopItems = setInterval(() => {
            if (count < accordionItems.length) {
              const input = accordionItems[count].querySelector('input[type="checkbox"]');
              input.checked = true;
              if (count > 0) {
                const prevInput = accordionItems[count - 1].querySelector('input[type="checkbox"]');
                prevInput.checked = false;
              }

              if (mediaColumnImages.length > 1) {
                // removing classes to prepare for fading images
                mediaColumnImages[count].classList.remove('hide');
                mediaColumnImages[count - 1].classList.remove('fade-in', 'animated');

                // adding classes to fade in image
                mediaColumnImages[count].classList.add('fade-in');
                mediaColumnImages[count - 1].classList.add('fade-out');
              }

              progressBar[count - 1].style = 'width: 0%; transition: none;';
              progressBar[count].style = `width: 100%; transition: width ${maxDuration}ms linear;`;
              count += 1;
            } else {
              progressBar[count - 1].style = 'width: 0%; transition: none;';
              clearInterval(loopItems);
            }
          }, maxDuration);

          // set active accordion item on click
          accordionItems.forEach((item, index) => {
            item.addEventListener('click', () => {
              clearInterval(loopItems);
              // eslint-disable-next-line no-param-reassign
              progressBar.forEach(timer => {
                // eslint-disable-next-line no-param-reassign
                timer.style = 'width: 0%; transition: none;';
              });
              accordionItems.forEach((it, i) => {
                if (index !== i) {
                  accordionItems[i].querySelector('input[type="checkbox"]').checked = false;
                } else {
                  accordionItems[index].querySelector('input[type="checkbox"]').checked =
                    !accordionItems[index].querySelector('input[type="checkbox"]').checked;
                }
              });
              if (mediaColumnImages.length > 1) {
                setAnimatedAccordionImage(mediaColumnImages, index);
              }
            });
          });

          observer.disconnect();
        }
      });
    },
    { threshold: [0.7] }
  );

  io.observe(element);
};

window.addEventListener('load', () => {
  const animatedAccordions = document.querySelectorAll('.bam-animated-accordion');
  setAnimatedAccordionImages();
  animatedAccordions.forEach(initAnimatedAccordion);
});
